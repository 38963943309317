export default {
  year: 2011,
  contests: [
    {
      title: "2011 ACM-ICPC Asia Daejeon Regional Contest",
      columns: ["#", "=", "팀", "멤버"],
      data: [
        ["7", "6", "Chamfaky", "백승환, 조규현, 김동규"],
        ["9", "4", "Conquer", "최백준, 김택희, 나원익"],
        ["17", "2", "SGAC", "정지윤, 정해인, 한윤정"],
        ["HM", "-", "docontae", "엄기문, 최도근, 홍태석"],
      ],
      award: ["silver", "", "", ""],
      links: [["공식 사이트", "http://icpckorea.org/2011-daejeon/regional"]],
      review: [],
    },
    {
      title: "2011 ACM-ICPC Asia Daejeon Preliminary Contest",
      columns: ["#", "=", "팀", "멤버"],
      data: [
        ["7", "6", "Conquer", "최백준, 김택희, 나원익"],
        ["25", "4", "Chamfaky", "백승환, 조규현, 김동규"],
        ["28", "4", "docontae", "엄기문, 최도근, 홍태석"],
        ["41", "3", "SGAC", "정지윤, 정해인, 한윤정"],
        ["61", "2", "Bong", "-"],
        ["95", "2", "The_wizard_of_OZ", "-"],
        ["112", "2", "SLUG", "-"],
        ["127", "2", "sasuatang", "-"],
        ["HM", "-", "Tosorl", "-"],
      ],
      award: [
        "advanced",
        "advanced",
        "advanced",
        "advanced",
        "",
        "",
        "",
        "",
        "",
      ],
      links: [["공식 사이트", "http://icpckorea.org/2011-daejeon/regional"]],
      review: [],
    },
  ],
};
