export default {
  year: 2007,
  contests: [
    {
      title: "2007 ACM-ICPC Asia Seoul Regional Contest",
      columns: ["#", "=", "팀", "멤버"],
      data: [["6", "-", "Kuru", "김우현, 안재원, 최백준"]],
      award: ["silver"],
      links: [["공식 사이트", "http://icpckorea.org/2007-seoul/regional"]],
      review: [],
    },
  ],
};
