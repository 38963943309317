export default {
  year: 2020,
  contests: [
    {
      title: "2020 ICPC Asia Seoul Regional Contest",
      columns: ["#", "=", "팀", "멤버"],
      data: [
        ["11", "6", "BlackWeasel", "이기현, 이윤제, 임지환"],
        ["16", "5", "GrayWater", "김주현, 안향빈, 이상원"],
      ],
      award: ["", ""],
      links: [
        ["공식 사이트", "http://icpckorea.org/2020-seoul/regional"],
        ["스코어보드", "http://static.icpckorea.net/2020/scoreboard_terpin"],
      ],
      review: [
        [
          "임지환(raararaara)",
          "https://blog.naver.com/raararaara/222151570413",
        ],
      ],
    },
    {
      title: "2020 ICPC Asia Seoul Preliminary Contest",
      columns: ["#", "=", "팀", "멤버"],
      data: [
        ["32", "6", "BlackWeasel", "이기현, 이윤제, 임지환"],
        ["36", "5", "Redshift", "박수현, 이준석, 전해성"],
        ["50", "5", "GrayWater", "김주현, 안향빈, 이상원"],
        ["60", "5", "JunSeong_Go_Army", "박세원, 박준성, 이영진"],
        ["63", "5", "kimchi_men", "김관우, 배철환, 이길원"],
        ["76", "4", "Terra", "이동주, 이민희, 조원빈"],
        ["82", "4", "blueDJL", "강효규, 박건, 손기령"],
        ["84", "4", "ACMong us", "-"],
        ["139", "3", "3vs500", "강민석, 권형준, 장수길"],
        ["174", "2", "Orange-Line", "김가희, 노지연, 임지선"],
        ["206", "1", "giveMe_O|n|", "강상원, 강승구, 김진수"],
        ["228", "1", "TennyBear", "김태안, 임해진, 홍주희"],
        ["314", "1", "Gyugle", "권태훈, 김미소, 박규리"],
        ["334", "0", "JOJO", "계인혜, 권지은, 한찬희"],
      ],
      award: [
        "advanced",
        "",
        "advanced",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
      ],
      links: [
        ["공식 사이트", "http://icpckorea.org/2020-seoul/regional"],
        [
          "스코어보드",
          "http://icpckorea.org/2020/preliminary/scoreboard/dbda78f0e4",
        ],
      ],
      review: [
        [
          "임지환(raararaara)",
          "https://blog.naver.com/raararaara/222117403817",
        ],
        ["강효규(djs100201)", "https://physicsmathcoumputer.tistory.com/12"],
      ],
    },
    {
      title: "Google Kick Start Round A 2020",
      columns: ["#", "=", "닉네임", "이름"],
      data: [
        ["116 / 🇰🇷8", "100", "semteo04", "이준석"],
        ["640 / 🇰🇷34", "100", "weasel", "이윤제"],
        ["765 / 🇰🇷37", "100", "gumgood", "이상원"],
        ["838 / 🇰🇷42", "100", "raararaara", "임지환"],
      ],
      award: ["", "", "", ""],
      links: [
        ["공식 사이트", "https://codingcompetitions.withgoogle.com/kickstart"],
        [
          "스코어보드",
          "https://codingcompetitions.withgoogle.com/kickstart/round/000000000019ffc7",
        ],
      ],
      review: [],
    },
    {
      title: "Google Kick Start Round B 2020",
      columns: ["#", "=", "닉네임", "이름"],
      data: [
        ["169 / 🇰🇷7", "76", "semteo04", "이준석"],
        ["1,917 / 🇰🇷51", "62", "gumgood", "이상원"],
      ],
      award: ["", ""],
      links: [
        ["공식 사이트", "https://codingcompetitions.withgoogle.com/kickstart"],
        [
          "스코어보드",
          "https://codingcompetitions.withgoogle.com/kickstart/round/000000000019ffc8",
        ],
      ],
      review: [],
    },
    {
      title: "Google Kick Start Round C 2020",
      columns: ["#", "=", "닉네임", "이름"],
      data: [
        ["730 / 🇰🇷19", "62", "rhksdn6227", "김관우"],
        ["2,953 / 🇰🇷62", "22", "pjh6792", "박재형"],
      ],
      award: ["", ""],
      links: [
        ["공식 사이트", "https://codingcompetitions.withgoogle.com/kickstart"],
        [
          "스코어보드",
          "https://codingcompetitions.withgoogle.com/kickstart/round/000000000019ff43",
        ],
      ],
      review: [],
    },
    {
      title: "Google Kick Start Round D 2020",
      columns: ["#", "=", "닉네임", "이름"],
      data: [
        ["1,093 / 🇰🇷26", "55", "rhksdn6227", "김관우"],
        ["1,120 / 🇰🇷27", "55", "weasel", "이윤제"],
        ["1,127 / 🇰🇷28", "55", "pjh6792", "박재형"],
      ],
      award: ["", "", ""],
      links: [
        ["공식 사이트", "https://codingcompetitions.withgoogle.com/kickstart"],
        [
          "스코어보드",
          "https://codingcompetitions.withgoogle.com/kickstart/round/000000000019ff08",
        ],
      ],
      review: [],
    },
    {
      title: "Google Kick Start Round E 2020",
      columns: ["#", "=", "닉네임", "이름"],
      data: [
        ["484 / 🇰🇷18", "39", "weasel", "이윤제"],
        ["977 / 🇰🇷29", "30", "pjh6792", "박재형"],
        ["2,967 / 🇰🇷56", "11", "rhksdn6227", "김관우"],
      ],
      award: ["", "", ""],
      links: [
        ["공식 사이트", "https://codingcompetitions.withgoogle.com/kickstart"],
        [
          "스코어보드",
          "https://codingcompetitions.withgoogle.com/kickstart/round/000000000019ff47",
        ],
      ],
      review: [],
    },
    {
      title: "Google Kick Start Round F 2020",
      columns: ["#", "=", "닉네임", "이름"],
      data: [
        ["1,136 / 🇰🇷17", "41", "minigimbob", "이민희"],
        ["1,900 / 🇰🇷31", "29", "djs100201", "강효규"],
      ],
      award: ["", ""],
      links: [
        ["공식 사이트", "https://codingcompetitions.withgoogle.com/kickstart"],
        [
          "스코어보드",
          "https://codingcompetitions.withgoogle.com/kickstart/round/000000000019ff48",
        ],
      ],
      review: [],
    },
    {
      title: "Google Kick Start Round G 2020",
      columns: ["#", "=", "닉네임", "이름"],
      data: [
        ["353 / 🇰🇷12", "72", "pjh6792", "박재형"],
        ["421 / 🇰🇷14", "72", "minigimbob", "이민희"],
        ["1,064 / 🇰🇷34", "55", "djs100201", "강효규"],
      ],
      award: ["", "", ""],
      links: [
        ["공식 사이트", "https://codingcompetitions.withgoogle.com/kickstart"],
        [
          "스코어보드",
          "https://codingcompetitions.withgoogle.com/kickstart/round/00000000001a0069",
        ],
      ],
      review: [],
    },
    {
      title: "Google Code Jam Round 2 2020",
      columns: ["#", "=", "닉네임", "이름"],
      data: [
        ["1,140 / 🇰🇷69", "38", "semteo04", "이준석"],
        ["1,427 / 🇰🇷81", "28", "shiftpsh", "박수현"],
        ["2,871 / 🇰🇷153", "14", "gumgood", "이상원"],
      ],
      award: ["", "", ""],
      links: [
        ["공식 사이트", "https://codingcompetitions.withgoogle.com/codejam"],
        [
          "스코어보드",
          "https://codingcompetitions.withgoogle.com/codejam/round/000000000019ffb9",
        ],
      ],
      review: [],
    },
    {
      title: "Google Code Jam Round 1A 2020",
      columns: ["#", "=", "닉네임", "이름"],
      data: [
        ["814 / 🇰🇷51", "72", "semteo04", "이준석"],
        ["1,228 / 🇰🇷69", "63", "gumgood", "이상원"],
        ["1,693 / 🇰🇷95", "51", "shiftpsh", "박수현"],
        ["4,498", "28", "weasel", "이윤제"],
      ],
      award: ["advanced", "advanced", "", ""],
      links: [
        ["공식 사이트", "https://codingcompetitions.withgoogle.com/codejam"],
        [
          "스코어보드",
          "https://codingcompetitions.withgoogle.com/codejam/round/000000000019fd74",
        ],
      ],
      review: [],
    },
    {
      title: "Google Code Jam Round 1B 2020",
      columns: ["#", "=", "닉네임", "이름"],
      data: [
        ["277 / 🇰🇷17", "63", "shiftpsh", "박수현"],
        ["2,667", "29", "weasel", "이윤제"],
      ],
      award: ["advanced", ""],
      links: [
        ["공식 사이트", "https://codingcompetitions.withgoogle.com/codejam"],
        [
          "스코어보드",
          "https://codingcompetitions.withgoogle.com/codejam/round/000000000019fef2",
        ],
      ],
      review: [],
    },
    {
      title: "Google Hash Code 2020 Online Qualification Round",
      columns: ["#", "=", "팀", "멤버"],
      data: [
        [
          "2,055 / 🇰🇷29",
          "25,046,312",
          "Redshift+",
          "박건, 박수현, 이용욱, 이준석",
        ],
      ],
      award: [""],
      links: [
        ["공식 사이트", "https://codingcompetitions.withgoogle.com/hashcode"],
        [
          "스코어보드",
          "https://hashcodejudge.withgoogle.com/#/rounds/6313004828196864/scoreboards",
        ],
      ],
      review: [],
    },
    {
      title: "SUAPC 2020 Summer division 1",
      columns: ["#", "=", "팀", "멤버"],
      data: [
        ["2", "6", "talsogang", "김주현, 이상원, 이태한"],
        ["3", "5", "BlackWeasel", "이기현, 이윤제, 임지환"],
        [
          "5",
          "3",
          "처음보는사람들끼리신청마감1시간전에만든팀",
          "김성현, 박재형, 전해성",
        ],
      ],
      award: ["silver", "silver", "bronze"],
      links: [
        ["문제", "https://www.acmicpc.net/category/detail/2274"],
        ["스코어보드", "https://www.acmicpc.net/contest/spotboard/519"],
      ],
      review: [
        [
          "임지환(raararaara)",
          "https://blog.naver.com/raararaara/222062261143",
        ],
        ["박재형(pjh6792)", "https://rebro.kr/56"],
        ["이윤제(yjyj1027)", "https://blog.naver.com/yjyj1027/222062589168"],
      ],
    },
    {
      title: "SUAPC 2020 Summer division 2",
      columns: ["#", "=", "팀", "멤버"],
      data: [
        ["1", "6", "Terra", "이동주, 이민희, 조원빈"],
        ["2", "5", "한놈만노린다", "이수빈, 최재영, 황성민"],
      ],
      award: ["gold", "silver"],
      links: [
        ["문제", "https://www.acmicpc.net/category/detail/2275"],
        ["스코어보드", "https://www.acmicpc.net/contest/spotboard/518"],
      ],
      review: [],
    },
    {
      title: "2020 ICPC Sinchon Summer Algorithm Camp Contest - 중급",
      columns: ["#", "=", "닉네임", "이름"],
      data: [
        ["1", "5", "rhksdn6227", "김관우"],
        ["3", "3", "leese0425", "이승은"],
      ],
      award: ["gold", "bronze"],
      links: [
        ["문제", "https://www.acmicpc.net/category/detail/2290"],
        ["스코어보드", "https://www.acmicpc.net/contest/board/533"],
      ],
      review: [],
    },
    {
      title: "Samsung Collegiate Programming Contest 2020 Finals",
      columns: ["#", "=", "이름"],
      data: [
        ["5", "228", "이상원"],
        ["-", "172", "박수현"],
      ],
      award: ["", ""],
      links: [
        ["공식 사이트", "https://www.codeground.org/scpc/commons/honer/list"],
      ],
      review: [],
    },
    {
      title: "UCPC 2020 본선",
      columns: ["#", "=", "팀", "멤버"],
      data: [
        ["37", "4", "전대프연 회장 임기는 최소 2년", "전해성, 이준석, -"],
        ["64", "3", "BlackWeasel", "이기현, 이윤제, 임지환"],
        ["75", "3", "도찡개찐", "이영진, 박세원, 박준성"],
        ["77", "3", "rhrnak", "김주현, 이상원, 이태한"],
        ["99", "2", "Unicorn", "이민희, 송은우, 조원빈"],
        ["118", "1", "temp", "정성엽, 이예은, 김인호"],
        ["130", "1", "3호선", "임지선, 김가희, 노지연"],
        ["131", "1", "동글이안경", "최재영, 이수빈, 황성민"],
        ["133", "1", "3vs500", "권형준, 강민석, 장수길"],
        ["135", "1", "리알", "손기령, -, -"],
        ["141", "1", "건시채", "-"],
      ],
      award: ["", "", "", "", "", "", "", "", "", "", ""],
      links: [
        ["문제", "http://ucpc.me/assets/ucpc20-finals-problems.pdf"],
        ["해설", "http://ucpc.me/assets/ucpc20-finals-solutions.pdf"],
        ["스코어보드", "https://www.acmicpc.net/contest/spotboard/524"],
      ],
      review: [],
    },
  ],
};
