export default {
  year: 2018,
  contests: [
    {
      title: "2018 ACM-ICPC Asia Seoul Regional Contest",
      columns: ["#", "=", "팀", "멤버"],
      data: [
        ["15", "5", "no team name", "이준석, 최윤영, 최지원"],
        ["HM", "2", "Hodadak", "손정연, 윤기영, 정진욱"],
        ["HM", "1", "Minsu_go_Army", "엄태경, 이용욱, 전민수"],
      ],
      award: ["special", "", ""],
      links: [
        ["공식 사이트", "http://icpckorea.org/2018-seoul/regional"],
        ["스코어보드", "http://icpckorea.org/2018/regional/scoreboard"],
      ],
      review: [],
    },
    {
      title: "2018 ACM-ICPC Asia Seoul Preliminary Contest",
      columns: ["#", "=", "팀", "멤버"],
      data: [
        ["31", "7", "Minsu_go_Army", "엄태경, 이용욱, 전민수"],
        ["35", "7", "no team name", "이준석, 최윤영, 최지원"],
        ["63", "7", "Hodadak", "손정연, 윤기영, 정진욱"],
        ["77", "6", "BREAK_A_LEG", "김인호, 이예은, 최민영"],
        ["157", "4", "CheeseStick", "김연후, 임지환, 류동훈"],
        ["197", "3", "nextLevel", "강문해, 성시철, 이진훈"],
        ["237", "3", "ASSAACM", "박상욱, 이재하, 정현태"],
        ["247", "3", "uzu", "송인아, 이동승, 허남규"],
        ["257", "3", "algorinewbie", "박수현, 이경희, 이태한"],
        ["376", "2", "myhairisgrey", "-"],
        ["583", "1", "GabDdongZone", "민동학, 윤성호, 이승재"],
      ],
      award: [
        "advanced",
        "advanced",
        "advanced",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
      ],
      links: [
        ["공식 사이트", "http://icpckorea.org/2018-seoul/regional"],
        ["스코어보드", "http://icpckorea.org/2018/preliminary/scoreboard"],
      ],
      review: [],
    },
    {
      title: "Google Code Jam Round 3 2018",
      columns: ["#", "=", "닉네임", "이름"],
      data: [["HM", "-", "semteo04", "이준석"]],
      award: [""],
      links: [
        [
          "공식 사이트",
          "https://codejam.withgoogle.com/2018/challenges/0000000000007707",
        ],
      ],
      review: [],
    },
    {
      title: "Google Code Jam Round 2 2018",
      columns: ["#", "=", "닉네임", "이름"],
      data: [
        ["523", "68", "semteo04", "이준석"],
        ["HM", "-", "gs15120", "최지원"],
      ],
      award: ["advanced", ""],
      links: [
        [
          "공식 사이트",
          "https://codejam.withgoogle.com/2018/challenges/0000000000007706",
        ],
      ],
      review: [],
    },
    {
      title: "Google Code Jam Round 1A 2018",
      columns: ["#", "=", "닉네임", "이름"],
      data: [
        ["472", "84", "semteo04", "이준석"],
        ["566", "79", "gs15120", "최지원"],
        ["1702", "39", "shiftpsh", "박수현"],
      ],
      award: ["advanced", "advanced", ""],
      links: [
        [
          "공식 사이트",
          "https://codejam.withgoogle.com/2018/challenges/0000000000007883",
        ],
      ],
      review: [],
    },
    {
      title: "Google Code Jam Round 1C 2018",
      columns: ["#", "=", "닉네임", "이름"],
      data: [
        ["1893", "44", "qjrmsktso2", "이용욱"],
        ["1987", "40", "shiftpsh", "박수현"],
      ],
      award: ["", ""],
      links: [
        [
          "공식 사이트",
          "https://codejam.withgoogle.com/2018/challenges/0000000000007765",
        ],
      ],
      review: [],
    },
    {
      title: "UCPC 2018 본선",
      columns: ["#", "=", "팀", "멤버"],
      data: [
        ["15", "6", "팀 이름 못 정한 팀", "이준석, 최윤영, 최지원"],
        ["48", "2", "뚝배기123!@", "정진욱, 손정연, 윤기영"],
        ["50", "1", "☒", "전민수, 정기웅, 엄태경"],
      ],
      award: ["", "", ""],
      links: [
        ["공식 사이트", "https://2018.ucpc.io"],
        ["스코어보드", "https://ucpc.acmicpc.net/contest/spotboard/314"],
      ],
      review: [],
    },
  ],
};
