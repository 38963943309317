export default {
  year: 2005,
  contests: [
    {
      title: "2005 ACM-ICPC Asia Seoul Regional Contest",
      columns: ["#", "=", "팀", "멤버"],
      data: [["9", "-", "Weird-OS", "김영욱, 심재은, 이한승"]],
      award: ["bronze"],
      links: [["공식 사이트", "http://icpckorea.org/2005-seoul/regional"]],
      review: [],
    },
  ],
};
