export default {
  year: 2020,
  edition: "제 16회",
  date: "2020년 11월 28일 토요일",
  time: "오후 2시 ~ 5시",
  location: "Online",
  links: {
    problems: {
      PDF: {
        Master: "/downloads/spc/2020/master.pdf",
        Champion: "/downloads/spc/2020/champion.pdf",
      },
      BOJ: "https://www.acmicpc.net/category/detail/2355",
    },
    solutions: {
      PDF: "/downloads/spc/2020/solutions.pdf",
    },
  },
  authors: ["이기현", "이윤제", "임지환", "김주현", "안향빈", "이상원"],
  contests: [
    {
      title: "SPC 2020 Master",
      columns: ["#", "=", "이름"],
      data: [
        ["1", "5", "강효규"],
        ["2", "5", "이민희"],
        ["3", "4", "박건"],
        ["4", "4", "신동준"],
        ["5", "4", "강다혜"],
        ["6", "4", "이동주"],
      ],
      award: ["gold", "silver", "silver", "bronze", "bronze", "bronze"],
      links: [["스코어보드", "https://www.acmicpc.net/contest/scoreboard/562"]],
    },
    {
      title: "SPC 2020 Champion",
      columns: ["#", "=", "이름"],
      data: [
        ["1", "4", "고성빈"],
        ["2", "3", "조원빈"],
        ["3", "3", "정성엽"],
        ["4", "3", "박준성"],
        ["5", "2", "김인호"],
        ["6", "2", "박우열"],
        ["7", "2", "이영진"],
      ],
      award: [
        "winner",
        "gold",
        "silver",
        "silver",
        "bronze",
        "bronze",
        "bronze",
      ],
      links: [["스코어보드", "https://www.acmicpc.net/contest/scoreboard/563"]],
    },
  ],
};
