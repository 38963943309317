export default {
  year: 2013,
  contests: [
    {
      title: "2013 ACM-ICPC Asia Daejeon Regional Contest",
      columns: ["#", "=", "팀", "멤버"],
      data: [
        ["8", "8", "I Might Be Wrong", "김선영, 최백준, 박현수"],
        ["10", "7", "BalloonCollector", "-"],
        ["12", "7", "I Might Be Accepted", "김상근, 고창영, 김현우"],
        ["13", "6", "Mentalist", "-"],
        ["HM", "-", "GoHome", "-"],
      ],
      award: ["silver", "", "", "", ""],
      links: [["공식 사이트", "http://icpckorea.org/2013-daejeon/regional"]],
      review: [],
    },
    {
      title: "2013 ACM-ICPC Asia Daejeon Preliminary Contest",
      columns: ["#", "=", "팀", "멤버"],
      data: [
        ["14", "6", "BalloonCollector", "-"],
        ["20", "5", "I Might Be Wrong", "김선영, 최백준, 박현수"],
        ["21", "5", "Mentalist", "-"],
        ["33", "5", "I Might Be Accepted", "김상근, 고창영, 김현우"],
        ["65", "4", "GoHome", "-"],
        ["70", "4", "jyj", "-"],
        ["93", "3", "GaeJJURY", "-"],
        ["98", "3", "developers rift", "-"],
        ["108", "3", "CBro", "-"],
        ["124", "3", "yoonujun", "-"],
        ["HM", "1", "Analyajum", "-"],
        ["HM", "1", "Fire_Fox", "-"],
        ["HM", "1", "jaeger", "-"],
      ],
      award: [
        "advanced",
        "advanced",
        "advanced",
        "advanced",
        "advanced",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
      ],
      links: [["공식 사이트", "http://icpckorea.org/2013-daejeon/regional"]],
      review: [],
    },
  ],
};
