export default {
  year: 2023,
  contests: [
    {
      title: "2023 ICPC Asia Jakarta Regional Contest",
      columns: ["#", "=", "팀", "멤버"],
      data: [["15", "6", "Redshift", "박수현, 이준석, 채성우"]],
      award: ["advanced"],
      links: [
        ["공식 사이트", "https://competition.binus.ac.id/icpc2023"],
        ["스코어보드", "https://competition.binus.ac.id/icpc2023/final.html"],
      ],
      review: [],
    },
    {
      title: "2023 ICPC Asia Seoul Regional Contest",
      columns: ["#", "=", "팀", "멤버"],
      data: [
        ["19", "6", "Redshift", "박수현, 이준석, 채성우"],
        ["29", "5", "Raa FanClub", "송근수, 이승형, 박성준"],
        ["58", "3", "WooJeans", "정회윤, 유호영, 이우진"],
      ],
      award: ["", "advanced", ""],
      links: [
        ["공식 사이트", "http://icpckorea.org/2023-seoul/regional"],
        ["스코어보드", "http://static.icpckorea.net/2023/regional/scoreboard"],
      ],
      review: [
        [
          "임지환(raararaara, 스태프 후기)",
          "https://blog.naver.com/raararaara/223297965004",
        ],
        ["채성우(lem0nad3)", "https://blog.naver.com/vermeil_/223284913439"],
      ],
    },
    {
      title: "2023 ICPC Asia Seoul Preliminary Contest",
      columns: ["#", "=", "팀", "멤버"],
      data: [
        ["17", "6", "Redshift", "박수현, 이준석, 채성우"],
        ["19", "6", "may raa knows", "김동건, 강효규, 조원빈"],
        ["52", "4", "Raa FanClub", "송근수, 이승형, 박성준"],
        ["59", "4", "WooJeans", "정회윤, 유호영, 이우진"],
        ["86", "3", "Dinner4Us", "권지은, 정파란, 류지성"],
        ["159", "2", "Doolythelizard", "박준영, 박정원, 조다니엘"],
        ["216", "1", "Moralbomb", "이성진, 이준우, 최호진"],
      ],
      award: ["advanced", "", "advanced", "advanced", "", "", ""],
      links: [
        ["공식 사이트", "http://icpckorea.org/2023-seoul/regional"],
        [
          "스코어보드",
          "http://static.icpckorea.net/2023/first_round/scoreboard/",
        ],
      ],
      review: [
        ["김동건(dong_gas)", "https://blog.naver.com/dong_gas/223244723211"],
      ],
    },
    {
      title: "Samsung Collegiate Programming Contest 2023 Finals",
      columns: ["#", "이름"],
      data: [
        ["-", "강효규"],
        ["-", "김동건"],
        ["-", "조원빈"],
        ["-", "이승형"],
        ["-", "박성준"],
        ["-", "송근수"],
      ],
      award: [""],
      links: [
        ["공식 사이트", "https://www.codeground.org/scpc/commons/honer/list"],
      ],
      review: [],
    },
    {
      title: "SUAPC 2023 Summer",
      columns: ["#", "=", "팀", "멤버"],
      data: [
        ["1", "12", "Redshift", "박수현, 이준석, 채성우"],
        [
          "3",
          "11",
          "월파출신 코치가 가르치고 있어요",
          "박성준, 송근수, 이승형",
        ],
        ["7", "9", "조성훈", "김동건, 조성훈, 조원빈"],
        ["8", "8", "기령손의 그녀들", "강효규, 김서인, 장유빈"],
        ["9", "7", "WooJeans", "이우진, 유호영, 정회윤"],
      ],
      award: ["gold", "silver", "", "", ""],
      links: [
        ["공식 사이트", "https://icpc-sinchon.io/suapc"],
        ["스코어보드", "https://www.acmicpc.net/contest/spotboard/1104"],
      ],
      review: [
        ["채성우(lem0nad3)", "https://blog.naver.com/vermeil_/223200563487"],
      ],
    },
    {
      title: "2023 ICPC Sinchon Summer Algorithm Camp Contest - Novice",
      columns: ["#", "=", "닉네임", "이름"],
      data: [
        ["1", "5", "alzflost", "김현서"],
        ["2", "4", "midamida", "변미담"],
        ["3", "3", "aprilfo01s", "임정연"],
      ],
      award: ["gold", "silver", "bronze"],
      links: [
        ["공식 사이트", "https://icpc-sinchon.io/campcontest"],
        ["스코어보드", "https://www.acmicpc.net/contest/spotboard/1101"],
      ],
      review: [
        ["정회윤(yunny_world)", "https://yunny.world/80"],
        ["채성우(lem0nad3)", "https://blog.naver.com/vermeil_/223189879406"],
      ],
    },
    {
      title: "23년 현대모비스 알고리즘 경진대회(학생부)",
      columns: ["#", "이름"],
      data: [
        ["우수상", "박성준"],
        ["장려상", "송근수"],
        ["장려상", "이승형"],
      ],
      award: [""],
      links: [
        ["공식 사이트", "https://career.programmers.co.kr/competitions/3419"],
      ],
      review: [],
    },
    {
      title: "23년 현대모비스 알고리즘 경진대회(일반부)",
      columns: ["#", "이름"],
      data: [
        ["우수상", "이윤제"],
        ["우수상", "임지환"],
        ["장려상", "한상필"],
      ],
      award: [""],
      links: [
        ["공식 사이트", "https://career.programmers.co.kr/competitions/3419"],
      ],
      review: [
        [
          "임지환(raararaara)",
          "https://blog.naver.com/raararaara/223150522621",
        ],
        ["이윤제(yjyj1027)", "https://blog.naver.com/yjyj1027/223150325945"],
      ],
    },
    {
      title: "SUAPC 2023 Winter",
      columns: ["#", "=", "팀", "멤버"],
      data: [
        ["5", "6", "혼혈 서강..준", "이승형, 이준석, 송근수"],
        ["6", "6", "상 타면 군대 감ㅅㄱ", "손기령, 신동준, 김서인"],
        [
          "7",
          "6",
          "-------------------10등컷---------------------",
          "김정모, 이소영, 박준하",
        ],
        ["8", "6", "tksvlf123의 last dance", "이우진, 유호영, 한상필"],
      ],
      award: ["bronze", "bronze", "", ""],
      links: [
        ["공식 사이트", "https://icpc-sinchon.io/suapc"],
        ["스코어보드", "https://www.acmicpc.net/contest/spotboard/950"],
      ],
      review: [["이지훈(maker29)", "https://maker29.tistory.com/2"]],
    },
    {
      title: "2023 ICPC Sinchon Winter Algorithm Camp Contest - Advanced",
      columns: ["#", "=", "닉네임", "이름"],
      data: [
        ["1", "5", "tmdgud0617", "이승형"],
        ["2", "3", "lem0nad3", "채성우"],
      ],
      award: ["gold", ""],
      links: [
        ["공식 사이트", "https://icpc-sinchon.io/campcontest"],
        ["스코어보드", "https://www.acmicpc.net/contest/spotboard/948"],
      ],
      review: [
        ["유호영(tkfkddl59323)", "https://loftol.tistory.com/6"],
        ["정회윤(yunny_world)", "https://yunny.world/65"],
      ],
    },
    {
      title: "2023 ICPC Sinchon Winter Algorithm Camp Contest - Novice",
      columns: ["#", "=", "닉네임", "이름"],
      data: [["1", "6", "gi9410", "김기철"]],
      award: ["gold"],
      links: [
        ["공식 사이트", "https://icpc-sinchon.io/campcontest"],
        ["스코어보드", "https://www.acmicpc.net/contest/spotboard/947"],
      ],
      review: [],
    },
  ],
};
