export default {
  year: 2012,
  edition: "제 8회",
  date: "2012년 11월 29일 목요일",
  time: "오후 6시 30분 ~ 9시 30분",
  location: "리치과학관 R912 / R914",
  links: {
    problems: {
      BOJ: null,
    },
  },
  authors: [],
  contests: [],
};
