export default {
  year: 2011,
  edition: "제 7회",
  date: "",
  time: "",
  location: "",
  links: {
    problems: {
      BOJ: null,
    },
  },
  authors: [],
  contests: [],
};
