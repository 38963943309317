export default {
  year: 2016,
  contests: [
    {
      title: "2016 ACM-ICPC Asia Daejeon Regional Contest",
      columns: ["#", "=", "팀", "멤버"],
      data: [
        ["12", "5", "Trilobite", "-"],
        ["HM", "3", "NINANO", "-"],
      ],
      award: ["bronze", ""],
      links: [
        ["공식 사이트", "http://icpckorea.org/2016-daejeon/regional"],
        ["스코어보드", "http://icpckorea.org/2016/REGIONAL/scoreboard.html"],
      ],
      review: [],
    },
    {
      title: "2016 ACM-ICPC Asia Daejeon Preliminary Contest",
      columns: ["#", "=", "팀", "멤버"],
      data: [
        ["33", "6", "Trilobite", "-"],
        ["49", "5", "NINANO", "-"],
        ["65", "5", "SIGKILL", "-"],
        ["67", "5", "Last_Chance_in_Life", "-"],
        ["100", "4", "FrozenMango", "-"],
        ["108", "4", "Mipil", "-"],
        ["145", "3", "DongzhuBuxuzou", "-"],
        ["156", "3", "golangG", "-"],
        ["195", "3", "DrunkenPikachu", "-"],
        ["197", "3", "Kingston", "-"],
        ["240", "3", "WooJooBonSeon", "-"],
        ["246", "2", "Talsogang", "-"],
        ["299", "2", "Daeheung", "-"],
        ["348", "2", "pro_programmer", "-"],
        ["549", "1", "mojito", "-"],
        ["550", "1", "OMEGAMON", "-"],
      ],
      award: [
        "advanced",
        "advanced",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
      ],
      links: [
        ["공식 사이트", "http://icpckorea.org/2016-daejeon/regional"],
        ["스코어보드", "http://icpckorea.org/2016/ONLINE/scoreboard"],
      ],
      review: [],
    },
    {
      title: "UCPC 2016 본선",
      columns: ["#", "=", "팀", "멤버"],
      data: [
        ["30", "5", "DrunkenPikachu", "-"],
        ["35", "5", "talsogang", "-"],
        ["50", "3", "Overheat", "-"],
        ["53", "3", "algorhythm", "-"],
        ["54", "3", "AngMerDdi", "-"],
      ],
      award: ["", "", "", "", ""],
      links: [],
      review: [],
    },
  ],
};
