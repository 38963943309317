export default {
  year: 2009,
  contests: [
    {
      title: "2009 ACM-ICPC Asia Seoul Regional Contest",
      columns: ["#", "=", "팀", "멤버"],
      data: [
        ["7", "6", "SNSD", "김우현, 안재원, 최백준"],
        ["20", "2", "Johannes Krauser II", "김동혁, 류재명, 신동준"],
        ["25", "2", "lychee", "-"],
      ],
      award: ["silver", "", ""],
      links: [["공식 사이트", "http://icpckorea.org/2009-seoul/regional"]],
      review: [],
    },
    {
      title: "2009 ACM-ICPC Asia Amritapuri Regional Contest",
      columns: ["#", "=", "팀", "멤버"],
      data: [["1", "7", "Oasis", "김우현, 안재원, 최백준"]],
      award: ["first,advanced"],
      links: [
        [
          "공식 사이트",
          "https://icpc.baylor.edu/regionals/finder/amritapuri-2009",
        ],
      ],
      review: [],
    },
    {
      title: "Java Algorithm Contest",
      columns: ["#", "팀", "멤버"],
      data: [["3", "KTX", "최백준 (+ 타교생 2인)"]],
      award: [""],
      links: [],
      review: [],
    },
  ],
};
