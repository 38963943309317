export default {
  year: 2019,
  contests: [
    {
      title: "2019 ICPC Asia Seoul Regional Contest",
      columns: ["#", "=", "팀", "멤버"],
      data: [
        ["8", "7", "Redshift", "박건, 박수현, 이준석"],
        ["22", "5", "Hororok", "손정연, 윤기영, 정진욱"],
        ["23", "4", "Yonguk_go_Army", "신해빈, 이용욱, 이태한"],
      ],
      award: ["encouragement", "", ""],
      links: [
        ["공식 사이트", "http://icpckorea.org"],
        ["스코어보드", "http://icpckorea.org/2019/regional/scoreboard"],
      ],
      review: [],
    },
    {
      title: "2019 ICPC Asia Bangkok Regional Contest",
      columns: ["#", "=", "팀", "멤버"],
      data: [["31", "3", "Redshift", "박건, 박수현, 이준석"]],
      award: [""],
      links: [
        ["공식 사이트", "https://acm-icpc.eng.chula.ac.th"],
        ["스코어보드", "http://icpc-scoreboard.eng.chula.ac.th"],
      ],
      review: [],
    },
    {
      title: "2019 ICPC Asia Seoul Preliminary Contest",
      columns: ["#", "=", "팀", "멤버"],
      data: [
        ["17", "6", "Redshift", "박건, 박수현, 이준석"],
        ["50", "5", "Yonguk_go_Army", "신해빈, 이용욱, 이태한"],
        ["52", "5", "Hororok", "손정연, 윤기영, 정진욱"],
        ["73", "4", "___---O_", "이기현, 이윤제, 임세홍"],
        ["115", "4", "PureWater", "김주현, 안향빈, 이상원"],
        ["211", "3", "141617", "이동승, 임지환, 최민영"],
        ["355", "3", "adidas2019", "김동환, 정필립, 허준형"],
        ["376", "2", "AMNT", "류인석, 신동윤, 이석준"],
        ["429", "2", "1000CC", "강민석, 권형준, 정진원"],
        ["463", "2", "GGgo", "강상원, 김진수, 윤상현"],
        ["491", "2", "yeonyo_has_an_idea", "김연요, 김연후, 손동현"],
        ["635", "1", "KDK", "김동규, 오새암, 천명철"],
        ["665", "1", "HSI", "계인혜, 김시은, 최한길"],
      ],
      award: [
        "advanced",
        "advanced",
        "advanced",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
      ],
      links: [
        ["공식 사이트", "http://icpckorea.org/2019-seoul/regional"],
        ["스코어보드", "http://icpckorea.org/2019/preliminary/scoreboard"],
      ],
      review: [],
    },
    {
      title: "Google Hash Code 2019 Online Qualification Round",
      columns: ["#", "=", "팀", "멤버"],
      data: [["842 / 🇰🇷9", "714,169", "Goldfish", "박건, 박수현, 이준석"]],
      award: [""],
      links: [
        ["공식 사이트", "https://codingcompetitions.withgoogle.com/hashcode"],
        [
          "스코어보드",
          "https://hashcodejudge.withgoogle.com/#/rounds/6417837228818432/scoreboards",
        ],
      ],
      review: [],
    },
    {
      title: "Google Kick Start Round A",
      columns: ["#", "=", "닉네임", "이름"],
      data: [
        ["365 / 🇰🇷16", "35", "shiftpsh", "박수현"],
        ["901", "35", "semteo04", "이준석"],
        ["1526", "20", "min05", "최민영"],
      ],
      award: ["", "", ""],
      links: [
        ["공식 사이트", "https://codingcompetitions.withgoogle.com/kickstart"],
        [
          "스코어보드",
          "https://codingcompetitions.withgoogle.com/kickstart/round/0000000000050e01",
        ],
      ],
      review: [],
    },
    {
      title: "Google Kick Start Round H",
      columns: ["#", "=", "닉네임", "이름"],
      data: [["15 / 🇰🇷1", "100", "semteo04", "이준석"]],
      award: [""],
      links: [
        ["공식 사이트", "https://codingcompetitions.withgoogle.com/kickstart"],
        [
          "스코어보드",
          "https://codingcompetitions.withgoogle.com/kickstart/round/0000000000050edd",
        ],
      ],
      review: [],
    },
    {
      title: "Google Code Jam Round 1A 2019",
      columns: ["#", "=", "닉네임", "이름"],
      data: [["238 / 🇰🇷12", "100", "shiftpsh", "박수현"]],
      award: ["advanced"],
      links: [
        ["공식 사이트", "https://codingcompetitions.withgoogle.com/codejam"],
        [
          "스코어보드",
          "https://codingcompetitions.withgoogle.com/codejam/round/0000000000051635",
        ],
      ],
      review: [],
    },
    {
      title: "Google Code Jam Round 1B 2019",
      columns: ["#", "=", "닉네임", "이름"],
      data: [["892 / 🇰🇷41", "72", "semteo04", "이준석"]],
      award: ["advanced"],
      links: [
        ["공식 사이트", "https://codingcompetitions.withgoogle.com/codejam"],
        [
          "스코어보드",
          "https://codingcompetitions.withgoogle.com/codejam/round/0000000000051706",
        ],
      ],
      review: [],
    },
    {
      title: "Samsung Collegiate Programming Contest 2019 Onsite Finals",
      columns: ["#", "=", "이름"],
      data: [
        ["-", "597", "이준석"],
        ["-", "453", "박수현"],
      ],
      award: ["", ""],
      links: [
        ["공식 사이트", "https://www.codeground.org/scpc/commons/honer/list"],
      ],
      review: [],
    },
    {
      title: "UCPC 2019 본선",
      columns: ["#", "=", "팀", "멤버"],
      data: [
        [
          "10",
          "6",
          "팀명 나중에 정하자",
          "박한나, 김영현(SNUPS), 심유근(SNUPS)",
        ],
      ],
      award: [""],
      links: [
        ["공식 사이트", "https://ucpc-kr.github.io"],
        ["스코어보드", "https://ucpc.acmicpc.net/contest/spotboard/450"],
      ],
      review: [],
    },
  ],
};
