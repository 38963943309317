export default {
  year: 2008,
  contests: [
    {
      title: "2008 ACM-ICPC Asia Seoul Regional Contest",
      columns: ["#", "=", "팀", "멤버"],
      data: [["8", "-", "Coderani", "김우현, 안재원, 최백준"]],
      award: ["silver"],
      links: [["공식 사이트", "http://icpckorea.org/2008-seoul/regional"]],
      review: [],
    },
    {
      title: "2008 ACM-ICPC Asia Kuala Lumpur Regional Contest",
      columns: ["#", "=", "팀", "멤버"],
      data: [["14", "-", "Greenzilla", "김우현, 안재원, 최백준"]],
      award: [""],
      links: [["공식 사이트", "http://icpckorea.org/2008-seoul/regional"]],
      review: [],
    },
    {
      title: "Google Code Jam APAC Semifinal 2008",
      columns: ["#", "=", "닉네임", "이름"],
      data: [["121", "7", "Baekjoon", "최백준"]],
      award: [""],
      links: [
        [
          "공식 사이트",
          "https://code.google.com/codejam/contest/32005/dashboard",
        ],
      ],
      review: [],
    },
    {
      title: "Google Code Jam Round 3 2008",
      columns: ["#", "=", "닉네임", "이름"],
      data: [["361", "20", "Baekjoon", "최백준"]],
      award: ["advanced"],
      links: [
        [
          "공식 사이트",
          "https://code.google.com/codejam/contest/32002/dashboard",
        ],
      ],
      review: [],
    },
    {
      title: "Google Code Jam Round 2 2008",
      columns: ["#", "=", "닉네임", "이름"],
      data: [["851", "25", "Baekjoon", "최백준"]],
      award: ["advanced"],
      links: [
        [
          "공식 사이트",
          "https://code.google.com/codejam/contest/32001/dashboard",
        ],
      ],
      review: [],
    },
    {
      title: "Google Code Jam Round 1B 2008",
      columns: ["#", "=", "닉네임", "이름"],
      data: [["643", "15", "Baekjoon", "최백준"]],
      award: ["advanced"],
      links: [
        [
          "공식 사이트",
          "https://code.google.com/codejam/contest/32017/dashboard",
        ],
      ],
      review: [],
    },
    {
      title: "Java Algorithm Contest",
      columns: ["#", "팀", "멤버"],
      data: [["3", "C#", "김우현, 안재원, 최백준"]],
      award: ["bronze"],
      links: [],
      review: [],
    },
  ],
};
