export default {
  year: 2017,
  contests: [
    {
      title: "2017 ACM-ICPC Asia Daejeon Regional Contest",
      columns: ["#", "=", "팀", "멤버"],
      data: [
        ["11", "7", "BlueJoker", "박건, 이준석, 최윤영"],
        ["17", "5", "GidoMeta", "고성빈, 정성엽, 하태훈"],
      ],
      award: ["bronze", ""],
      links: [
        ["공식 사이트", "http://icpckorea.org/2017-daejeon/regional"],
        ["스코어보드", "http://icpckorea.org/2017/regional/scoreboard"],
      ],
      review: [],
    },
    {
      title: "2017 ACM-ICPC Asia Daejeon Preliminary Contest",
      columns: ["#", "=", "팀", "멤버"],
      data: [
        ["57", "4", "BlueJoker", "박건, 이준석, 최윤영"],
        ["58", "4", "GidoMeta", "고성빈, 정성엽, 하태훈"],
        ["63", "4", "EvaChamchi", "-"],
        ["116", "4", "common", "-"],
        ["126", "4", "503_not_found", "-"],
        ["208", "3", "talljosen", "-"],
        ["228", "3", "MeutCiel", "-"],
        ["257", "3", "HOING", "-"],
        ["270", "3", "triad", "-"],
        ["308", "2", "ReskipUZU", "-"],
        ["309", "2", "John D Carmack 2", "-"],
        ["332", "2", "SHOLGIT", "-"],
      ],
      award: ["advanced", "advanced", "", "", "", "", "", "", "", "", "", ""],
      links: [
        ["공식 사이트", "http://icpckorea.org/2017-daejeon/regional"],
        ["스코어보드", "http://icpckorea.org/2017/preliminary/scoreboard"],
      ],
      review: [],
    },
    {
      title: "Google Code Jam Round 3 2017",
      columns: ["#", "=", "닉네임", "이름"],
      data: [["283", "13", "Kaseo", "한상덕"]],
      award: [""],
      links: [
        [
          "공식 사이트",
          "https://code.google.com/codejam/contest/8304486/dashboard",
        ],
      ],
      review: [],
    },
    {
      title: "Google Code Jam Round 2 2017",
      columns: ["#", "=", "닉네임", "이름"],
      data: [
        ["483", "37", "Kaseo", "한상덕"],
        ["HM", "-", "ainch", "최윤영"],
      ],
      award: ["advanced", ""],
      links: [
        [
          "공식 사이트",
          "https://code.google.com/codejam/contest/5314486/dashboard",
        ],
      ],
      review: [],
    },
    {
      title: "Google Code Jam Round 1A 2017",
      columns: ["#", "=", "닉네임", "이름"],
      data: [
        ["3918", "21", "ainch", "최윤영"],
        ["4060", "21", "Kaseo", "한상덕"],
      ],
      award: ["", ""],
      links: [
        [
          "공식 사이트",
          "https://code.google.com/codejam/contest/5304486/dashboard",
        ],
      ],
      review: [],
    },
    {
      title: "Google Code Jam Round 1B 2017",
      columns: ["#", "=", "닉네임", "이름"],
      data: [
        ["163", "100", "Kaseo", "한상덕"],
        ["4979", "25", "ainch", "최윤영"],
      ],
      award: ["advanced", ""],
      links: [
        [
          "공식 사이트",
          "https://code.google.com/codejam/contest/8294486/dashboard",
        ],
      ],
      review: [],
    },
    {
      title: "Google Code Jam Round 1C 2017",
      columns: ["#", "=", "닉네임", "이름"],
      data: [["589", "72", "ainch", "최윤영"]],
      award: ["advanced"],
      links: [
        [
          "공식 사이트",
          "https://code.google.com/codejam/contest/3274486/dashboard",
        ],
      ],
      review: [],
    },
    {
      title: "Samsung Collegiate Programming Contest 2017 Onsite Finals",
      columns: ["#", "이름"],
      data: [["5", "한상덕"]],
      award: [""],
      links: [
        ["공식 사이트", "https://www.codeground.org/scpc/commons/honer/list"],
      ],
      review: [],
    },
    {
      title: "2017 Kakao Code Festival 본선",
      columns: ["#", "=", "이름"],
      data: [
        ["36", "4", "한상덕"],
        ["60", "2", "최윤영"],
        ["76", "1", "이준석"],
      ],
      award: ["", "", ""],
      links: [
        ["공식 사이트", "https://www.kakaocode.com"],
        [
          "스코어보드",
          "http://t1.kakaocdn.net/codefestival/round-2-scoreboard/index.html",
        ],
      ],
      review: [],
    },
  ],
};
