export default {
  year: 2006,
  contests: [
    {
      title: "2006 ACM-ICPC Asia Seoul Regional Contest",
      columns: ["#", "=", "팀", "멤버"],
      data: [["-", "-", "IHS", "김영욱, 김우현, 최병현"]],
      award: [""],
      links: [["공식 사이트", "http://icpckorea.org/2006-seoul/regional"]],
      review: [],
    },
  ],
};
